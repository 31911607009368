import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/layout.css"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="text-center">
      <h1 style={{ fontFamily: "PT Sans Bold", fontSize: "4rem" }}>
        404: Not Found
      </h1>
      <p style={{ fontFamily: "WorkSans Regular", fontSize: "2rem" }}>
        You just hit a route that doesn&#39;t exist... the sadness.
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
